<template>
    <div class="search position-relative w-100 h-auto d-flex align-items-center">
        <label v-bind:for="`filterSelect${id}`"></label>
        <select v-bind:id="`filterSelect${id}`" aria-label="Search Filter" class="filter-select form-control w-auto mr-1 flex-grow-1 flex-shrink-0"
                v-bind:value="filterField"
                v-on:change="$emit('update:filterField', $event.target.value)">
            <option disabled hidden value=""></option>
            <option v-for="field in fields" v-bind:key="field.key" v-bind:value="field.key">
                {{ field.display }}
            </option>
        </select>
        <label v-bind:for="`searchInput${id}`"></label>
        <input type="text" v-bind:id="`searchInput${id}`" aria-label="Search" class="search-input form-control text-center flex-grow-1"
               v-bind:value="searchKeywords"
               v-on:input="$emit('update:searchKeywords', $event.target.value)"
               v-bind:placeholder="placeholder">
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    name: "Search",
    
    props: {
        fields: {
            type: Array,
            required: true
        },
        filterField: {
            type: String,
            required: true
        },
        searchKeywords: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        }
    },
	
	data() {
    	return {
    		id: uuidv4()
	    }
	}
}
</script>

<style lang="scss" scoped>
.search-input {
	min-width: 140px;
}
</style>