<template>
	<div class="data-table position-relative w-100 h-100 d-flex flex-column overflow-hidden">
		<div class="field-names-row position-relative w-100 h-auto bg-custom rounded-top d-flex align-items-center flex-shrink-0">
			<div v-if="selectable || multiSelectable" class="icon-reserve h-100 flex-shrink-0"></div>
			<h5 class="column position-relative h-auto p-2 m-0 text-white d-flex align-items-center"
			    v-for="{ key, classBinding, display } in fields"
			    v-bind:key="key"
			    v-bind:class="classBinding">
                    <span class="field-names text-center position-relative text-truncate flex-grow-1"
                          v-bind:title="display"
                          v-on:click="$emit('sort-data', key)">
                        {{ display }}
                    </span>
			</h5>
			<div class="scroll-reserve flex-shrink-0"></div>
		</div>
		<div class="data-rows scroll-bar position-relative w-100 h-100 rounded-bottom">
			<div class="data-row position-relative w-100 h-auto d-flex align-items-center"
			     v-for="data in dataArray"
			     v-bind:key="data._id"
			     v-bind:class="{'selected': selectedIds.includes(data._id)}"
			     v-on:click="$emit('row-clicked', data)">
				<div v-if="selectable || multiSelectable" class="icon-reserve h-auto p-2 flex-shrink-0 d-flex justify-content-center">
					<font-awesome-icon class="icon position-relative w-100 h-100"
					                   v-show="multiSelectable || selectedIds.includes(data._id)"
					                   v-bind:icon="['fas', 'check-circle']"
					                   v-bind:class="{'selected': selectedIds.includes(data._id)}">
					</font-awesome-icon>
				</div>
				<h5 class="column position-relative h-auto p-2 m-0 d-flex justify-content-center align-items-center"
				    v-for="{ key, classBinding } in fields"
				    v-bind:key="key"
				    v-bind:title="data[key]"
				    v-bind:class="classBinding">
					<span class="data-names text-center position-relative text-truncate">{{ data[key] }}</span>
				</h5>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DataTable",
	
	props: {
		fields: {
			type: Array,
			required: true
		},
		dataArray: {
			type: Array,
			required: true
		},
		selectable: {
			type: Boolean,
			required: true
		},
		multiSelectable: {
			type: Boolean,
			required: true
		},
		selectedIds: {
			type: Array,
			required: true,
		}
	},
}
</script>

<style lang="scss" scoped>
.scroll-reserve {
	width: 6px;
}

.icon-reserve {
	width: 40px;
	
	.icon {
		opacity: .25;
		transition: all .2s ease;
		
		&.selected {
			opacity: 1;
		}
	}
}

.data-rows {
	overflow-y: auto;
	background-color: rgba(0, 0, 0, .1);
	
	.data-row {
		cursor: pointer;
		transition: all .2s ease;
		
		&:hover, &.selected {
			background-color: rgba(0, 0, 0, .15);
		}
	}
}

.column {
	flex: 1 1 auto;
	width: 80px;
	min-width: 0;
	
	.field-names {
		cursor: pointer;
		user-select: none;
		
		&:hover {
			color: $color-theme-setting;
		}
	}
	
	&.f-2 {
		flex: 2 1 auto;
	}
	
	&.f-3 {
		flex: 3 1 auto;
	}
	
	&.f-4 {
		flex: 4 1 auto;
	}
	
	&.f-5 {
		flex: 5 1 auto;
	}
}
</style>