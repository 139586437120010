<template>
	<div class="table-select position-relative w-100 h-100 d-flex flex-column">
		<div class="controls position-relative w-100 h-auto mb-2 d-flex justify-content-between">
			<search v-bind:fields="fields"
			        v-bind:placeholder="`Search ${title}`"
			        v-bind:filter-field.sync="filterField"
			        v-bind:search-keywords.sync="searchKeywords"></search>
			<div class="table-buttons position-relative h-auto w-auto d-flex">
			<!--	set left margins in the button itself   -->
				<slot></slot>
			</div>
		</div>
		<data-table v-bind:fields="fields"
		            v-bind:data-array="sortedFilteredArray"
		            v-bind:selectable="selectable"
		            v-bind:multi-selectable="multiSelectable"
		            v-bind:selected-ids="selectedIds"
		            v-on:sort-data="sortData($event)"
		            v-on:row-clicked="$emit('row-clicked', $event)"></data-table>
	</div>
</template>

<script>
import Search from "@/components/functional/table/Search";
import DataTable from "@/components/functional/table/DataTable";
import { sortArrayObj } from "@/utility/helpers";

export default {
	name: "TableSelect",
	
	props: {
		title: {
			type: String,
			required: true
		},
		filter: {
			type: String,
			required: true
		},
		sort: {
			type: String,
			required: true
		},
		order: {
			type: Number,
			required: true
		},
		fields: {
			type: Array,
			required: true
		},
		dataArray: {
			type: Array,
			required: true
		},
		selectable: {
			type: Boolean,
			required: true
		},
		multiSelectable: {
			type: Boolean,
			required: true
		},
		selectedIds: {
			type: Array,
			required: true,
		}
	},
	
	components: {
		Search,
		DataTable,
	},
	
	data() {
		return {
			searchKeywords: "",
			filterField: this.filter,
			sortField: this.sort,
			// 1: ascending, -1: descending
			sortOrder: this.order,
		}
	},
	
	computed: {
		filteredArray() {
			return this.dataArray.filter(
				dataObj => dataObj[this.filterField].toLowerCase().includes(this.searchKeywords.toLowerCase())
			);
		},
		
		sortedFilteredArray() {
			// tackles vue reactivity caveats - array
			const arr = [...this.filteredArray]
			return sortArrayObj(arr, this.sortField, this.sortOrder);
		},
	},
	
	methods: {
		sortData(field) {
			if (this.sortField === field) {
				this.sortOrder = -this.sortOrder;
			} else {
				this.sortField = field;
				this.sortOrder = 1;
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.table-select {
	min-height: 0;
	
	.table-buttons {
		min-width: 0;
	}
}
</style>