var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table position-relative w-100 h-100 d-flex flex-column overflow-hidden"},[_c('div',{staticClass:"field-names-row position-relative w-100 h-auto bg-custom rounded-top d-flex align-items-center flex-shrink-0"},[(_vm.selectable || _vm.multiSelectable)?_c('div',{staticClass:"icon-reserve h-100 flex-shrink-0"}):_vm._e(),_vm._l((_vm.fields),function(ref){
var key = ref.key;
var classBinding = ref.classBinding;
var display = ref.display;
return _c('h5',{key:key,staticClass:"column position-relative h-auto p-2 m-0 text-white d-flex align-items-center",class:classBinding},[_c('span',{staticClass:"field-names text-center position-relative text-truncate flex-grow-1",attrs:{"title":display},on:{"click":function($event){return _vm.$emit('sort-data', key)}}},[_vm._v(" "+_vm._s(display)+" ")])])}),_c('div',{staticClass:"scroll-reserve flex-shrink-0"})],2),_c('div',{staticClass:"data-rows scroll-bar position-relative w-100 h-100 rounded-bottom"},_vm._l((_vm.dataArray),function(data){return _c('div',{key:data._id,staticClass:"data-row position-relative w-100 h-auto d-flex align-items-center",class:{'selected': _vm.selectedIds.includes(data._id)},on:{"click":function($event){return _vm.$emit('row-clicked', data)}}},[(_vm.selectable || _vm.multiSelectable)?_c('div',{staticClass:"icon-reserve h-auto p-2 flex-shrink-0 d-flex justify-content-center"},[_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.multiSelectable || _vm.selectedIds.includes(data._id)),expression:"multiSelectable || selectedIds.includes(data._id)"}],staticClass:"icon position-relative w-100 h-100",class:{'selected': _vm.selectedIds.includes(data._id)},attrs:{"icon":['fas', 'check-circle']}})],1):_vm._e(),_vm._l((_vm.fields),function(ref){
var key = ref.key;
var classBinding = ref.classBinding;
return _c('h5',{key:key,staticClass:"column position-relative h-auto p-2 m-0 d-flex justify-content-center align-items-center",class:classBinding,attrs:{"title":data[key]}},[_c('span',{staticClass:"data-names text-center position-relative text-truncate"},[_vm._v(_vm._s(data[key]))])])})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }